<template>
    <div :is="component_type" v-model="show_dialog" width="500">
        <v-card>
            <v-card-title>
                Add New Payment Method
            </v-card-title>
            <v-card-text>
                <v-form id="paymentForm" method="POST">
                    <v-row wrap>
                        <v-col cols="12">
                            <v-text-field v-model="cardData.cardNumber" label="Card Number" />
                        </v-col>
                        <v-col cols="6">
                            <v-select :items="months" v-model="cardData.month" label="Month" />
                        </v-col>
                        <v-col cols="6">
                            <v-select :items="years" v-model="cardData.year" label="Year" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="cardData.cardCode" label="Security Code" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="cardData.zipCode" label="Zip Code" />
                        </v-col>
                        <v-col cols="12" class="text-center">
                            <v-btn color="primary" block @click="sendPaymentDataToAnet()">Save Payment Method</v-btn>
                            <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
                            {{status}}
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import moment from 'moment'
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    name: "QSavePaymentProfile",
    props: ["value", "agentCode", "noDialog"], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        return {
            cardData: {
                cardNumber: null,
                month: null,
                year: null,
                cardCode: null,
                zipCode: null
            },
            show_dialog: false,
            loading: false,
            status: ""
        }
    },
    mounted: function() {

    },
    computed: {
        months: function() {
            var m = [];
            for (var i = 0; i < 12; i++) {
                m.push({
                    text: moment().month(i).format('MMMM'),
                    value: moment().month(i).format('MM'),
                });
            }
            return m;
        },
        years: function() {
            var m = [];
            for (var i = moment().year(); i < moment().year() + 20; i++) {
                m.push({
                    text: i,
                    value: i,
                });
            }
            return m;
        },
        component_type: function() {
            return typeof this.noDialog == "undefined" || this.noDialog == false ? "v-dialog" : "div"
        }
    },
    methods: {
        sendPaymentDataToAnet: function() {
            var authData = {};
            var g = this
            this.loading = true
            this.status = "Validating Credit Card"
            authData.clientKey = process.env.VUE_APP_MERHCANT_PUBLIC_KEY; // "YOUR PUBLIC CLIENT KEY";
            authData.apiLoginID = process.env.VUE_APP_MERCHANT_LOGIN_ID; //"YOUR API LOGIN ID";
            var secureData = {};
            secureData.authData = authData;
            secureData.cardData = this.cardData;
            Accept.dispatchData(secureData, function(data) {
                if (data.messages.resultCode == 'Error') {
                    g.loading = false
                    g.status = ''
                    var str = '';
                    data.messages.message.forEach(function(item) {
                        str = str + item.code + " : " + item.text;
                    })
                    g.showError(str)
                    return;
                }
                if (data.messages.resultCode == 'Ok') {
                    g.status = "Saving Payment Method"
                    //object to send to laravel backend.
                    var type = "MasterCard";
                    if (secureData.cardData.cardNumber.substring(0, 1) == "3") {
                        type = "American Express";
                    }
                    if (secureData.cardData.cardNumber.substring(0, 1) == "4") {
                        type = "Visa";
                    }
                    if (secureData.cardData.cardNumber.substring(0, 1) == "6") {
                        type = "Discover";
                    }
                    var payment_data = {
                        PaymentType: type,
                        PaymentLastFour: secureData.cardData.cardNumber.slice(-4),
                        PaymentExpiration: moment().month(secureData.cardData.month).year(secureData.cardData.year).format('YYYY-MM-DD'),
                        PaymentPayload: {
                            "descriptor": data.opaqueData.dataDescriptor,
                            "value": data.opaqueData.dataValue
                        },
                        ZipCode: secureData.cardData.zipCode
                    }
                    //now send this to the laravel back end for saving the payment info. 
                    QuilityAPI.addAccountPaymentMethod(g.agentCode, payment_data).then(function(json) {
                        g.loading = false;
                        g.status = '';
                        if (json.error) {
                            g.showError(json.msg);
                            return
                        }
                        g.showSuccess("Payment Method Saved!");
                        g.$emit('newcard')
                        g.show_dialog = false;
                    }).catch(function(err) {
                        g.loading = false;
                        g.status = '';
                        g.showError(err);
                    })
                    return
                } else {
                    g.loading = false
                }
            });
        },
        resetCard: function() {
            this.cardData = {
                cardNumber: null,
                month: null,
                year: null,
                cardCode: null,
                zipCode: null
            }
        }
    },
    watch: {
        'value': function(newV) {
            if (newV === true) {
                this.show_dialog = true;
            }
        },
        'show_dialog': function(newV) {
            if (newV === false) {
                this.$emit('input', false)
                this.resetCard();
            }
        }
    },
}
</script>
<style scoped>
</style>