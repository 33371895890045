<template>
    <q-submenu :base-url="baseUrl" :title="title" :titlelink="titlelink" :buttons="buttons" class="q-sticky"></q-submenu>
</template>
<script>
//table data + search
import QSubmenu from '@/components/navigation/Submenus/QSubmenu.vue'
export default {
    data() {
        return {
            title: "Agent Accounting",
            titlelink: null,
            baseUrl: '/agents/accounts',
            buttons: [{
                label: "Balances",
                to: "/agents/accounts",
                icon: "fas fa-arrow-alt-circle-right",
            }, {
                label: "Lead Sales Summary",
                to: "/leads/lead-sales-summary",
                icon: "fas fa-arrow-alt-circle-right",
            }, {
                label: "Lead Sales Receipts",
                to: "/leads/lead-sales-receipts",
                icon: "fas fa-arrow-alt-circle-right",
            }],
        }
    },
    computed: {},
    components: {
        QSubmenu
    },
    methods: {},
}
</script>
<style>
</style>