<template>
    <QTabs dark v-model="tab">
        <v-tab>
            Allocation History
        </v-tab>
        <v-tab>
            Order Audit History
        </v-tab>
        <v-tab>
            Order Line Audit History
        </v-tab>
        <v-tab-item>
            <div>
                <v-simple-table dense class="ml-10 lead-order-line-table">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th v-for="h in the_history_headers" :key="h.value" :class="alignClass(h)">
                                    <span v-html="h.text"></span>
                                </th>
                            </tr>
                        </thead>
                        <tbody v-for="order in orderHistory">
                            <tr>
                                <td v-for="h in the_history_headers" :key="h.value" :class="alignClass(h)">
                                    <strong><span v-if="h.value == 'CreateDate'">
                                            {{ formatDate(order['CreateDate'])}}
                                        </span>
                                        <span v-else v-html="order[h.value]"></span></strong>
                                </td>
                            </tr>
                            <tr v-if="order.Allocations.length > 0">
                                <td></td>
                                <td :colspan="the_history_headers.length - 1">
                                    <q-lead-allocation-table :allocations="order.Allocations"></q-lead-allocation-table>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </div>
        </v-tab-item>
        <v-tab-item>
            <q-audit-data-table key="LeadOrderAuditReport" :rows="50" :agent-code="orderNumber" api-function="getLeadOrderAudit" title="Order Audit Report"></q-audit-data-table>
        </v-tab-item>
        <v-tab-item>
            <q-audit-data-table key="LeadOrderAuditReport" :rows="50" :agent-code="orderNumber" api-function="getLeadOrderLineAudit" title="Order Audit Report"></q-audit-data-table>
        </v-tab-item>
    </QTabs>
</template>
<script>
import QLeadAllocationTable from '@/components/datatables/LeadOrders/QLeadAllocationTable.vue'
import QAuditDataTable from '@/components/datatables/Audit/QAuditDataTable.vue'
import QTabs from '../../navigation/QTabs.vue'

export default {
    name: "QLeadOrderHistoryTable",
    props: ['orderHistory', 'orderNumber'],
    data: function() { // data inter
        return {
            tab: null
        };
    },
    computed: {
        the_history_headers: function() {
            return [{
                text: 'Date',
                value: 'CreateDate',
                align: "left"
            }, {
                text: 'Order #',
                value: 'OrderNumber',
                align: "left"
            }, {
                text: 'Requested',
                value: 'QtyRequested',
                align: "center"
            }, {
                text: 'Filled',
                value: 'QtyFilled',
                align: "center"
            }, {
                text: 'ID',
                value: 'ID',
                align: "center"
            }]
        },
    },
    methods: {
        alignClass: function(header) {
            if (header.align == "center") {
                return "text-middle text-center"
            }
            if (header.align == "left") {
                return "text-start"
            }
            if (header.align == "right") {
                return "text-end"
            }
        },
    },
    components: {
        QLeadAllocationTable,
        QAuditDataTable,
        QTabs
    }
}

</script>
