<!--
this component is used in both My Leads and Available Leads
-->
<template>
    <q-submenu base-url="/leads" :title="title" :titlelink="titlelink" :buttons="buttons" :submenu="submenu" class="q-stycky"></q-submenu>
</template>
<script>
//table data + search
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QSubmenu from '@/components/navigation/Submenus/QSubmenu.vue'
export default {
    props: ["hasDemo"],
    data() {
        return {
            title: "Leads",
            titlelink: [{
                label: "view leads in OPT",
                fun: this.OptSSO
                //link: "https://sfgcrm1.com/index.aspx",
            }],
        }
    },
    computed: {
        submenu: function() {
            var g = this;
            var submenu = [{
                label: "Help",
                to: "/support"
            }, ];
            if (this.hasDemo) {
                submenu.push({
                    label: "Start Demo",
                    fun: function() {
                        g.$emit("demo");
                    }
                })
            }
            return submenu;
        },
        buttons: function() {
            var b = [{
                label: "My Leads",
                anchor: "#lead_datatable",
                icon: "fas fa-arrow-alt-circle-right",
            }];
            if (this.hasPermission("agent:DXLeadOrders") || this.hasPermission("agent:RecruitingLeadOrders") || this.hasPermission("agent:Quility Digital LeadLeadOrders") || this.hasPermission("agent:Debt Free LifeLeadOrders")) {
                b.push({
                    label: "Lead Orders",
                    to: "/leads/dx-lead-orders",
                    icon: "fas fa-arrow-alt-circle-right",
                    children: [{
                        label: "DX Lead Orders",
                        to: "/leads/dx-lead-orders"
                    }]
                })
                if (this.hasPermission("agent:Quility Digital LeadLeadOrders")) {
                    b[1].children.push({
                        label: "Quility Digital Lead Orders",
                        to: "/leads/quility-digital-lead-orders",
                        icon: "fas fa-arrow-alt-circle-right",
                    })
                }
                if (this.hasPermission("agent:Debt Free LifeLeadOrders")) {
                    b[1].children.push({
                        label: "Debt Free Lead Orders",
                        to: "/leads/dfl-lead-orders",
                        icon: "fas fa-arrow-alt-circle-right",
                    })
                }
                if (this.hasRole(['AgencyOwner', 'SuperAdmin', 'Exec', 'Staff'])) {
                    b[1].children.push({
                        label: "Approve Lead Orders",
                        to: "/leads/approve-digital-lead-orders",
                        icon: "fas fa-arrow-alt-circle-right",
                    })
                }
                if (this.hasRole("AgencyOwner")) {
                    b[1].children.push({
                        label: "Approve Lead Orders",
                        to: "/leads/approve-digital-lead-orders"
                    })
                }
            }
            /*
            if (this.hasRole("AgencyOwner")) {
                b[1].children.push({
                    label: "Approve Lead Orders",
                    to: "/leads/approve-digital-lead-orders",
                }),
                b[1].children.push({
                    label: "Recruiting Lead Orders",
                    to: "/leads/recruiting-lead-orders",
                })
            }*/
            b = [
                ...b,
                {
                    label: "Lead Fact Sheets",
                    to: "/page/leads-fact-sheets",
                    icon: "fas fa-arrow-alt-circle-right",
                },
                {
                    label: "Digital Leads Training",
                    to: "/page/digital-leads",
                    icon: "fas fa-arrow-alt-circle-right",
                }
            ]
            return b;
        }
    },
    components: {
        QSubmenu
    },
    methods: {
        OptSSO: function() {
            var g = this;
            QuilityAPI.getOptSsoUrl().then(function(json) {
                if (json.Success) {
                    window.open(json.Message, '_blank');
                } else {
                    g.showError(json.Message)
                }
            }).catch(function(err) {
                g.showError(err)
            })
        }
    },
}
</script>
<style>
</style>